module.exports = function SliderComponent(el, options){
    
    this.el = el;
    var currSlide = 0;
    var fullscreenSrc = [];
    var images;

    enableSlick();

    function enableSlick() {

      images = el.querySelectorAll('.carousel div');
      
      //initialize the fullscreen carousel when there is a data attribute slick-fullscreen
      if(images[0].getAttribute('data-slick-fullscreen')) {
        enableFullScreen();
      }

      //build the carousel with the provided options
      $(el.querySelector('ul')).slick(options);

      $(el.querySelector('ul')).on('afterChange', function (event, slick, currentSlide) {
        currSlide = currentSlide;
      });

    }

    function enableFullScreen() {
      //check the fullscreen assets and create the fullscreen button
      var carousel = el.querySelector('.carousel');

      //get the sources to build a fullscreen carousel if needed
      for (var i = 0; i < images.length; i++) {
        fullscreenSrc.push(images[i].getAttribute('data-slick-fullscreen'));
      }

      //create fullscreen button
      var button = document.createElement('button');
      button.classList.add('btn-expand');
      button.classList.add('js-btn-expand');
      button.innerHTML = "fullscreen";

      carousel.appendChild(button);

      button.addEventListener('click', fullscreenButtonClickHandler);
    }

    function buildCarousel(fullscreenSrc, currSlide) {

      if(!currSlide) currSlide = 0;

      var body = document.querySelector('body');

      //build the carousel container
      var carouselContainer = document.createElement('div');
      carouselContainer.classList.add('fullscreen-carousel');

      //create carousel list
      var carouselList = document.createElement('ul');
      carouselList.classList.add('active');
      carouselList.classList.add('js-fullscreen-carousel');

      //create close button
      var closeButton = document.createElement('button');
      closeButton.classList.add('btn-close');
      closeButton.classList.add('js-fullscreen-close');

      carouselContainer.appendChild(carouselList);
      carouselContainer.appendChild(closeButton);
      body.appendChild(carouselContainer);


      //get the container of the fullscreen carousel
      var container = document.querySelector('.js-fullscreen-carousel');

      //build a list item for each asset
      for(var i = 0; i<fullscreenSrc.length; i++) {
        var entry = document.createElement('li');
        var div = document.createElement('div');
        div.classList.add('fullscreen-carousel__image');
        div.setAttribute('style', 'background-image:url(' + fullscreenSrc[i] + ');');

        entry.appendChild(div);
        container.appendChild(entry);
      }
      document.querySelector('body').style.overflow = 'hidden';

      //build the carousel with Slick
      $('.js-fullscreen-carousel').not('.slick-initialized').slick({
        arrows: true,
        prevArrow: "<a class='btn-prev'></a>",
        nextArrow: "<a class='btn-next'></a>",
        initialSlide: currSlide,
        dots: false,
        customPaging: false,
        fade: false,
        autoplay: false,
        autoplaySpeed: 2000,
        speed: 300,
        infinite: true,
        focusOnSelect: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      });

      //animate the carousel into screen
      TweenMax.to('.fullscreen-carousel', 0.5, {x: '100%' , ease: Power1.easeOut});

      //get the close button and add a clickhandler
      var btnClose = document.querySelector('.js-fullscreen-close');
      btnClose.addEventListener('click', closeClickHandler);

    }

    function closeClickHandler(e) {
      document.querySelector('body').style.overflow = 'visible';
      
      //animate the carousel out of the screen
      TweenMax.to('.fullscreen-carousel', 0.5, {x: '0%' , ease: Power1.easeIn, onComplete: destroyCarousel});

    }
    
    function destroyCarousel() {
      //remove the carousel functions and remove it from the DOM
      $('.js-fullscreen-carousel').slick("unslick");
      var container = document.querySelector('.fullscreen-carousel');
      var body = document.querySelector('body');
      body.removeChild(container);
    }

    function fullscreenButtonClickHandler(e) {
      //start building the fullscreen carousel
      buildCarousel(fullscreenSrc, currSlide);

    }

    return SliderComponent;
};