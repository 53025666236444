var jqueryUniform = require('../vendor/jquery.uniform.min');
var SlickModule = require('./utils/ts.SlickModule');

$(document).ready(function () {

  // makes sure the whole site and images is loaded
  $("body").removeClass("loading");


  // Init ScollMagic controller
  controller = new ScrollMagic.Controller();


  // Add class "viewed" to sections
  $('section').each(function () {
    new ScrollMagic.Scene({triggerElement: this, triggerHook: 0.85})
      .setClassToggle(this, 'viewed')
      //.addIndicators({name: "Add class"})
      .addTo(controller);
  });


  // Uniform (+ responsive)
  $("select, input").uniform({fileDefaultHtml: 'Select a file please'});
  $("div.selector select , div.selector span , div.selector").css('width', '100%');


  // ScrollTo Btn
  controller.scrollTo(function (newpos) {
    TweenMax.to(window, 0.5, {scrollTo: {y: newpos - 115, autoKill: false}});
  });
  $(document).on("click", "a[data-scroll]", function (e) {

    var id = $(this).attr("data-scroll");

    if ($(id).length > 0) {
      e.preventDefault();
      controller.scrollTo(id);

      // if supported by the browser we can even update the URL.
      if (window.history && window.history.pushState) {
        history.pushState("", document.title, id);
      }
    }
  });

  // Open mobile nav OR popup
  $("a[data-popup]").click(function (e) {
    e.preventDefault();

    var popup = $(this).attr("data-popup");

    $("body").toggleClass(popup + "-open");
  });


  //create sliders
  var sliders = document.querySelectorAll('.block.slider');
  for (var i = sliders.length - 1; i >= 0; i--) {
    var slider = new SlickModule(sliders[i], {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: "<a class='btn-prev'></a>",
      nextArrow: "<a class='btn-next'></a>",
      autoplay: false,
      autoplaySpeed: 3000,
      speed: 500,
      focusOnSelect: false
    }); // + options meegeven
    
  }

  var blocks = document.querySelectorAll('.block');
  var tlIn = new TimelineMax({delay: 0.4});
  tlIn.staggerFromTo(blocks, 0.5, {opacity: 0, y: 50}, {opacity: 1, y: 0, ease: Power1.easeOut}, 0.1);


});





